import request from '../request/http'

const prefix = '/api/v1'


export const upStudentOrder = (params) => {
    return request({
        url: `${prefix}/student/order`,
        method: 'put',
        data: params,
    })
}

export const studentOrder = (params) => {
    return request({
        url: `${prefix}/student/order`,
        method: 'get',
        params: params,
    })
}

export const learnRecord = (params) => {
    return request({
        url: `${prefix}/student/learnRecord`,
        method: 'post',
        data: params,
    })
}
/**
 * 用户机构(列表查询)
 * @param {*} params
 */
export const agreement = (params) => {
    return request({
        url: `${prefix}/agreement`,
        method: 'get',
        params: params,
    })
}

/**
 * 批量开通课程
 * @param {*} params
 */
export const studentBasicListPut = (params) => {
    return request({
        url: `${prefix}/studentBasic/list`,
        method: 'put',
        data: params,
    })
}




/**
 * 发送短信验证码
 * @param {*} params
 */
export const smscode = (params) => {
    return request({
        url: `${prefix}/send/smscode`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户登录(短信验证码) 
 * @param {*} params
 */
export const userlogin = (params) => {
    return request({
        url: `${prefix}/user/login/smscode`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户登录(密码)
 * @param {*} params
 */
export const passwordlogin = (params) => {
    return request({
        url: `${prefix}/user/login`,
        method: 'post',
        data: params,
    })
}


/**
 * 修改用户密码(免登录)
 * @param {*} params
 */
export const resetpassword = (params) => {
    return request({
        url: `${prefix}/user/reset/password`,
        method: 'post',
        data: params,
    })
}


/**
 * 设置用户密码(鉴权)
 * @param {*} params
 */
export const setpassword = (params) => {
    return request({
        url: `${prefix}/user/set/password`,
        method: 'post',
        data: params,
    })
}


/**
 * 用户机构切换
 * @param {*} params
 */
export const companychoose = (params) => {
    return request({
        url: `${prefix}/user/company/choose`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户机构(列表查询)
 * @param {*} params
 */
export const companyslist = (params) => {
    return request({
        url: `${prefix}/user/companys`,
        method: 'get',
        params: params,
    })
}



/**
 * 用户信息(查询) 
 * @param {*} query
 */
export const userInfo = (query) => {
    return request({
        url: `${prefix}/user`,
        method: 'get',
        params: query,
    })
}



/**
 * 账户信息(查询)
 * @param {*} query
 */
export const companyaccount = (query) => {
    return request({
        url: `${prefix}/company/account`,
        method: 'get',
        params: query,
    })
}

/**
 * 院校列表 
 * @param {*} query
 */
export const collegeList = (query) => {
    return request({
        url: `${prefix}/college/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 学院列表 
 * @param {*} query
 */
export const academylist = (query) => {
    return request({
        url: `${prefix}/academy/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 学院专业列表 
 * @param {*} query
 */
export const majorlist = (query) => {
    return request({
        url: `${prefix}/academy/major/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 科目列表
 * @param {*} params
 */
export const subjectlist = (params) => {
    return request({
        url: `${prefix}/subject/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 全部专业列表 
 * @param {*} query
 */
export const allmajorlist = (query) => {
    return request({
        url: `${prefix}/major/list`,
        method: 'get',
        params: query,
    })
}


/**
 * 学员信息(创建)
 * @param {*} params
 */
export const createstudent = (params) => {
    return request({
        url: `${prefix}/student`,
        method: 'post',
        data: params,
    })
}

/**
 * 学员信息(查询)
 * @param {*} query
 */
export const querystudent = (query) => {
    return request({
        url: `${prefix}/student`,
        method: 'get',
        params: query,
    })
}


/**
 * 学员信息(列表)
 * @param {*} query
 */
export const studentList = (query) => {
    return request({
        url: `${prefix}/student/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 学员信息(修改) 
 * @param {*} params
 */
export const editstudent = (params) => {
    return request({
        url: `${prefix}/student`,
        method: 'put',
        data: params,
    })
}



/**
 * 学员信息(批量创建)
 * @param {*} params
 */
export const createstudentlist = (params) => {
    return request({
        url: `${prefix}/student/list`,
        method: 'post',
        data: params,
    })
}

/**
 * 学员信息(批量删除)
 * @param {*} params
 */
export const deletestudentlist = (params) => {
    return request({
        url: `${prefix}/student/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 学员信息(批量修改)
 * @param {*} params
 */
export const editstudentlist = (params) => {
    return request({
        url: `${prefix}/student/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 用户角色(创建)
 * @param {*} params
 */
export const createuserrole = (params) => {
    return request({
        url: `${prefix}/user/role`,
        method: 'post',
        data: params,
    })
}


/**
 * 用户角色(修改) 
 * @param {*} params
 */
export const edituserrole = (params) => {
    return request({
        url: `${prefix}/user/role`,
        method: 'put',
        data: params,
    })
}

/**
 * 用户角色(删除) 
 * @param {*} params
 */
export const deleteuserrole = (params) => {
    return request({
        url: `${prefix}/user/role`,
        method: 'delete',
        data: params,
    })
}


/**
 * 用户角色(列表查询)
 * @param {*} params
 */
export const userrolelist = (params) => {
    return request({
        url: `${prefix}/user/role/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 用户部门(创建)
 * @param {*} params
 */
export const createuserdept = (params) => {
    return request({
        url: `${prefix}/user/dept`,
        method: 'post',
        data: params,
    })
}


/**
 * 用户部门(列表查询)
 * @param {*} params
 */
export const userdeptlist = (params) => {
    return request({
        url: `${prefix}/user/dept/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 用户部门(删除)
 * @param {*} params
 */
export const deleteuserdept = (params) => {
    return request({
        url: `${prefix}/user/dept`,
        method: 'delete',
        data: params,
    })
}


/**
 * 用户部门(修改)
 * @param {*} params
 */
export const edituserdept = (params) => {
    return request({
        url: `${prefix}/user/dept`,
        method: 'put',
        data: params,
    })
}

/**
 * 员工信息(创建)
 * @param {*} params
 */
export const createcompanyuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'post',
        data: params,
    })
}

/**
 * 员工信息(列表查询)
 * @param {*} params
 */
export const companyuserlist = (params) => {
    return request({
        url: `${prefix}/company/user/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 员工信息(删除)
 * @param {*} params
 */
export const deletecompanyuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'delete',
        data: params,
    })
}


/**
 * 员工信息(修改)
 * @param {*} params
 */
export const editcompanyuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'put',
        data: params,
    })
}

/**
 * 字典名称(查询) 
 * @param {*} query
 */
export const dictionary = (query) => {
    return request({
        url: `${prefix}/dictionary`,
        method: 'get',
        params: query,
    })
}

/**
 * 字典名称(创建)
 * @param {*} params
 */
export const createdictionary = (params) => {
    return request({
        url: `${prefix}/dictionary`,
        method: 'post',
        data: params,
    })
}

/**
 * 字典值(创建)
 * @param {*} params
 */
export const createdictionaryvalue = (params) => {
    return request({
        url: `${prefix}/dictionary/value`,
        method: 'post',
        data: params,
    })
}

/**
 * 字典值(删除) 
 * @param {*} params
 */
export const deletedictionaryvalue = (params) => {
    return request({
        url: `${prefix}/dictionary/value`,
        method: 'delete',
        data: params,
    })
}

/**
 * 字典值(批量修改)
 * @param {*} params
 */
export const editdictionaryvaluelist = (params) => {
    return request({
        url: `${prefix}/dictionary/value/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 学员跟进记录(创建)
 * @param {*} params
 */
export const createstudentadvance = (params) => {
    return request({
        url: `${prefix}/student/advance`,
        method: 'post',
        data: params,
    })
}

/**
 * 学员跟进记录(列表查询)
 * @param {*} params
 */
export const studentadvancelist = (params) => {
    return request({
        url: `${prefix}/student/advance/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 学员跟进记录(删除)
 * @param {*} params
 */
export const deletestudentadvance = (params) => {
    return request({
        url: `${prefix}/student/advance`,
        method: 'delete',
        data: params,
    })
}

/**
 * 学员跟进记录(修改)
 * @param {*} params
 */
export const editstudentadvance = (params) => {
    return request({
        url: `${prefix}/student/advance`,
        method: 'put',
        data: params,
    })
}


/**
 * 服务订单(创建)
 * @param {*} params
 */
export const createstudentorder = (params) => {
    return request({
        url: `${prefix}/student/order`,
        method: 'post',
        data: params,
    })
}


/**
 * 服务订单(批量修改)
 * @param {*} params
 */
export const editstudentorderlist = (params) => {
    return request({
        url: `${prefix}/student/order/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 服务订单(审核通过)
 * @param {*} params
 */
export const studentorderauditdone = (params) => {
    return request({
        url: `${prefix}/student/order/audit/done`,
        method: 'post',
        data: params,
    })
}


/**
 * 服务订单(审核通过)
 * @param {*} params
 */
export const studentorderauditfail = (params) => {
    return request({
        url: `${prefix}/student/order/audit/fail`,
        method: 'post',
        data: params,
    })
}

/**
 * 服务订单(列表查询) 
 * @param {*} params
 */
export const studentorderlist = (params) => {
    return request({
        url: `${prefix}/student/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 服务订单(修改)
 * @param {*} params
 */
export const editstudentorder = (params) => {
    return request({
        url: `${prefix}/student/order`,
        method: 'put',
        data: params,
    })
}


/**
 * 服务订单(批量删除)
 * @param {*} params
 */
export const deletestudentorderlist = (params) => {
    return request({
        url: `${prefix}/student/order/list`,
        method: 'delete',
        data: params,
    })
}



/**
 * 学员服务跟进记录(创建)服务订单
 * @param {*} params
 */
export const createserviceadvance = (params) => {
    return request({
        url: `${prefix}/student/service/advance`,
        method: 'post',
        data: params,
    })
}


/**
 * 学员服务跟进记录(列表查询)服务订单
 * @param {*} params
 */
export const serviceadvancelist = (params) => {
    return request({
        url: `${prefix}/student/service/advance/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 课程信息(列表查询)
 * @param {*} params
 */
export const companycourselist = (params) => {
    return request({
        url: `${prefix}/company/course/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 服务账单(列表查询) 
 * @param {*} params
 */
export const serviceorderlist = (params) => {
    return request({
        url: `${prefix}/company/service/order/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 课程信息(创建)
 * @param {*} params
 */
export const createcompanycourse = (params) => {
    return request({
        url: `${prefix}/company/course`,
        method: 'post',
        data: params,
    })
}


/**
 * 课程信息(列表查询)
 * @param {*} params
 */
export const courselist = (params) => {
    return request({
        url: `${prefix}/company/course/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 课程信息(修改)
 * @param {*} params
 */
export const editcompanycourse = (params) => {
    return request({
        url: `${prefix}/company/course`,
        method: 'put',
        data: params,
    })
}


/**
 * 课程信息(批量删除) 
 * @param {*} params
 */
export const deletecourselist = (params) => {
    return request({
        url: `${prefix}/company/course/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 发票抬头(创建)
 * @param {*} params
 */
export const createcompanytaxno = (params) => {
    return request({
        url: `${prefix}/company/taxno`,
        method: 'post',
        data: params,
    })
}

/**
 * 发票抬头(列表查询)
 * @param {*} params
 */
export const companytaxnolist = (params) => {
    return request({
        url: `${prefix}/company/taxno/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 设置信息(查询) 
 * @param {*} query
 */
export const companysetting = (query) => {
    return request({
        url: `${prefix}/company/setting`,
        method: 'get',
        params: query,
    })
}


/**
 * 设置信息(修改) 
 * @param {*} params
 */
export const editcompanysetting = (params) => {
    return request({
        url: `${prefix}/company/setting`,
        method: 'put',
        data: params,
    })
}

/**
 * 员工信息(删除)
 * @param {*} params
 */
export const deletecompanytaxno = (params) => {
    return request({
        url: `${prefix}/company/taxno`,
        method: 'delete',
        data: params,
    })
}


/**
 * 服务关注(创建) 
 * @param {*} params
 */
export const createservicefollow = (params) => {
    return request({
        url: `${prefix}/user/service/follow`,
        method: 'post',
        data: params,
    })
}

/**
 * 服务关注(删除)
 * @param {*} params
 */
export const deleteservicefollow = (params) => {
    return request({
        url: `${prefix}/user/service/follow`,
        method: 'delete',
        data: params,
    })
}

/**
 * 关注(创建)
 * @param {*} params
 */
export const createuserfollow = (params) => {
    return request({
        url: `${prefix}/user/follow`,
        method: 'post',
        data: params,
    })
}

/**
 * 关注(删除)
 * @param {*} params
 */
export const deleteuserfollow = (params) => {
    return request({
        url: `${prefix}/user/follow`,
        method: 'delete',
        data: params,
    })
}

/**
 * 三方支付(payment)
 * @param {*} params
 */
export const pay = (params) => {
    return request({
        url: `${prefix}/payment/pay`,
        method: 'post',
        data: params,
    })
}
/**
 * 三方支付(查询)
 * @param {*} params
 */
export const paycheck = (params) => {
    return request({
        url: `${prefix}/payment/pay`,
        method: 'get',
        params: params,
    })
}



/**
 * 开票申请(创建)
 * @param {*} params
 */
export const createinvoiceapply = (params) => {
    return request({
        url: `${prefix}/company/invoice/apply`,
        method: 'post',
        data: params,
    })
}

/**
 * 充值记录(列表查询) 
 * @param {*} params
 */
export const accountLog = (params) => {
    return request({
        url: `${prefix}/company/account/log`,
        method: 'get',
        params: params,
    })
}

export const rechargeorderlist = (params) => {
    return request({
        url: `${prefix}/company/recharge/order/list`,
        method: 'get',
        params: params,
    })
}
/**
 * 充值记录(创建)
 * @param {*} params
 */
export const createrechargeorder = (params) => {
    return request({
        url: `${prefix}/company/recharge/order`,
        method: 'post',
        data: params,
    })
}

/**
 * 开票申请(列表查询) 
 * @param {*} params
 */
export const invoiceapplylist = (params) => {
    return request({
        url: `${prefix}/company/invoice/apply/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 用户机构(列表查询)
 * @param {*} params
 */
export const usercompanys = (params) => {
    return request({
        url: `${prefix}/user/companys`,
        method: 'get',
        params: params,
    })
}

/**
 * 机构信息(查询) 
 * @param {*} query
 */
export const companyinfo = (query) => {
    return request({
        url: `${prefix}/company`,
        method: 'get',
        params: query,
    })
}



/**
 *  用户规则(创建)
 * @param {*} params
 */
export const createuserrule = (params) => {
    return request({
        url: `${prefix}/user/rule`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户规则(列表查询) 
 * @param {*} params
 */
export const userrulelist = (params) => {
    return request({
        url: `${prefix}/user/rule/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 用户规则(批量删除) 
 * @param {*} params
 */
export const deleteuserrulelist = (params) => {
    return request({
        url: `${prefix}/user/rule/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 用户规则(修改)
 * @param {*} params
 */
export const edituserrule = (params) => {
    return request({
        url: `${prefix}/user/rule`,
        method: 'put',
        data: params,
    })
}

/**
 * 服务账单(修改)
 * @param {*} params
 */
export const editserviceorder = (params) => {
    return request({
        url: `${prefix}/company/service/order`,
        method: 'put',
        data: params,
    })
}
/**
 * 服务账单(查询)
 * @param {*} params
 */
export const querycompanyserviceorder = (params) => {
    return request({
        url: `${prefix}/company/service/order`,
        method: 'get',
        params: params,
    })
}



/**
 *  缴纳保证金
 * @param {*} params
 */
export const deposittransfer = (params) => {
    return request({
        url: `${prefix}/company/account/deposit/transfer`,
        method: 'post',
        data: params,
    })
}


/**
 * 账户变动记录(列表查询) 
 * @param {*} params
 */
export const accountloglist = (params) => {
    return request({
        url: `${prefix}/company/account/log`,
        method: 'get',
        params: params,
    })
}



/**
 * 参数配置(查询)
 * @param {*} params
 */
export const queryconfigure = (params) => {
    return request({
        url: `${prefix}/configure`,
        method: 'get',
        params: params,
    })
}


/**
 *  师资订单(创建)
 * @param {*} params
 */
export const createteacherorder = (params) => {
    return request({
        url: `${prefix}/teacher/match/order`,
        method: 'post',
        data: params,
    })
}

/**
 * 师资订单(列表查询) 
 * @param {*} params
 */
export const teacherorderlist = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 师资订单(修改) 
 * @param {*} params
 */
export const editeacherorder = (params) => {
    return request({
        url: `${prefix}/teacher/match/order`,
        method: 'put',
        data: params,
    })
}

/**
 * 师资订单(批量修改) 
 * @param {*} params
 */
export const editteacherorderlist = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资订单(批量删除) 
 * @param {*} params
 */
export const deleteteacherorderlist = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 师资订单(修改)
 * @param {*} params
 */
export const editteacherorder = (params) => {
    return request({
        url: `${prefix}/teacher/match/order`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资订单跟进记录(列表查询)
 * @param {*} params
 */
export const teacherorderadvancelist = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/advance/list`,
        method: 'get',
        params: params,
    })
}


/**
 *  师资订单跟进记录(创建)
 * @param {*} params
 */
export const createteacherorderadvance = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/advance`,
        method: 'post',
        data: params,
    })
}

/**
 * 师资订单跟进记录(修改)
 * @param {*} params
 */
export const editteacherorderadvance = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/advance`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资订单跟进记录(删除)
 * @param {*} params
 */
export const deleteteacherorderadvance = (params) => {
    return request({
        url: `${prefix}/teacher/match/order/advance`,
        method: 'delete',
        data: params,
    })
}


/**
 *  师资派遣订单(创建)
 * @param {*} params
 */
export const createteacherdispatchorder = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order`,
        method: 'post',
        data: params,
    })
}


/**
 * 师资派遣订单(列表查询) 
 * @param {*} params
 */
export const teacherdispatchorderlist = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 师资派遣订单(修改) 
 * @param {*} params
 */
export const editeacherdispatchorder = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资派遣订单(批量修改) 
 * @param {*} params
 */
export const editteacherdispatchorderlist = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资派遣订单(批量删除) 
 * @param {*} params
 */
export const deleteteacherdispatchorderlist = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 师资派遣订单跟进记录(列表查询)
 * @param {*} params
 */
export const teacherdispatchorderadvancelist = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/advance/list`,
        method: 'get',
        params: params,
    })
}


/**
 *  师资派遣订单跟进记录(创建)
 * @param {*} params
 */
export const createteacherdispatchorderadvance = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/advance`,
        method: 'post',
        data: params,
    })
}

/**
 * 师资派遣订单跟进记录(修改)
 * @param {*} params
 */
export const editteacherdispatchorderadvance = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/advance`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资派遣订单跟进记录(删除)
 * @param {*} params
 */
export const deleteteacherdispatchorderadvance = (params) => {
    return request({
        url: `${prefix}/teacher/dispatch/order/advance`,
        method: 'delete',
        data: params,
    })
}


/**
 *  外包订单（修改）
 * @param {*} params
 */
export const outsourcingedit = (params) => {
    return request({
        url: `${prefix}/crmOut/outsourcing/edit`,
        method: 'post',
        data: params,
    })
}


/**
 *  服务记录（查询详情）
 * @param {*} params
 */
export const recorddetail = (params) => {
    return request({
        url: `${prefix}/crmOut/record/detail`,
        method: 'post',
        data: params,
    })
}


/**
 * 代签订单（修改）
 * @param {*} params
 */
export const signedBehalfedit = (params) => {
    return request({
        url: `${prefix}/crmOut/signedBehalf/edit`,
        method: 'post',
        data: params,
    })
}


/**
 * 代找订单（修改）
 * @param {*} params
 */
export const helpFindedit = (params) => {
    return request({
        url: `${prefix}/crmOut/helpFind/edit`,
        method: 'post',
        data: params,
    })
}


/**
 *  代找订单（查询详情）
 * @param {*} params
 */
export const helpFinddetail = (params) => {
    return request({
        url: `${prefix}/crmOut/helpFind/detail`,
        method: 'post',
        data: params,
    })
}

/**
 * 学生（查询列表）资料学员管理
 * @param {*} query
 */
export const studentBasiclist = (query) => {
    return request({
        url: `${prefix}/studentBasic/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 学生（批量删除）资料学员管理
 * @param {*} params
 */
export const studentBasiclistDelete = (params) => {
    return request({
        url: `${prefix}/studentBasic/list`,
        method: 'delete',
        data: params,
    })
}


/**
 *  学生（创建）资料学员管理
 * @param {*} params
 */
export const studentBasicCreate = (params) => {
    return request({
        url: `${prefix}/studentBasic`,
        method: 'post',
        data: params,
    })
}


/**
 * 学生（查询详情）
 * @param {*} query
 */
export const studentBasicOne = (query) => {
    return request({
        url: `${prefix}/studentBasic`,
        method: 'get',
        params: query,
    })
}


/**
 * 学生（修改)
 * @param {*} params
 */
export const studentBasicEdit = (params) => {
    return request({
        url: `${prefix}/studentBasic`,
        method: 'put',
        data: params,
    })
}


/**
 * 学生（删除）
 * @param {*} params
 */
export const studentBasicDelete = (params) => {
    return request({
        url: `${prefix}/studentBasic`,
        method: 'delete',
        data: params,
    })
}


/**
 *  学生（定校）
 * @param {*} params
 */
export const studentBasiccollegechoose = (params) => {
    return request({
        url: `${prefix}/studentBasic/college/choose`,
        method: 'post',
        data: params,
    })
}

/**
 *  学生（定服务）
 * @param {*} params
 */
export const confirmteacherService = (params) => {
    return request({
        url: `${prefix}/studentBasic/confirm/teacherService`,
        method: 'post',
        data: params,
    })
}


/**
 * 资料（创建)
 * @param {*} params
 */
export const creatematerial = (params) => {
    return request({
        url: `${prefix}/material`,
        method: 'post',
        data: params,
    })
}


/**
 * 资料（查询列表）
 * @param {*} query
 */
export const materiallist = (query) => {
    return request({
        url: `${prefix}/material/list`,
        method: 'get',
        params: query,
    })
}


/**
 * 资料（查询详情）
 * @param {*} query
 */
export const materialOne = (query) => {
    return request({
        url: `${prefix}/material`,
        method: 'get',
        params: query,
    })
}



/**
 * 资料（修改）
 * @param {*} params
 */
export const materialEdit = (params) => {
    return request({
        url: `${prefix}/material`,
        method: 'put',
        data: params,
    })
}

/**
 * 资料（批量更新）
 * @param {*} params
 */
export const materiallistEdit = (params) => {
    return request({
        url: `${prefix}/material/list`,
        method: 'put',
        data: params,
    })
}

/**
 * 资料（删除）
 * @param {*} params
 */
export const materialDelete = (params) => {
    return request({
        url: `${prefix}/material`,
        method: 'delete',
        data: params,
    })
}



/**
 * 资料（批量删除）
 * @param {*} params
 */
export const materiallistDelete = (params) => {
    return request({
        url: `${prefix}/material/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 库存单（创建） 
 * @param {*} params
 */
export const createstockslip = (params) => {
    return request({
        url: `${prefix}/stock/slip`,
        method: 'post',
        data: params,
    })
}


/**
 * 库存单（查询列表）
 * @param {*} query
 */
export const stocksliplist = (query) => {
    return request({
        url: `${prefix}/stock/slip/list`,
        method: 'get',
        params: query,
    })
}


/**
 * 库存单（删除）
 * @param {*} params
 */
export const stockslipDelete = (params) => {
    return request({
        url: `${prefix}/stock/slip`,
        method: 'delete',
        data: params,
    })
}

/**
 * 库存单（批量删除）
 * @param {*} params
 */
export const stocksliplistDelete = (params) => {
    return request({
        url: `${prefix}/stock/slip/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 库存单（查询详情）
 * @param {*} query
 */
export const stockslipOne = (query) => {
    return request({
        url: `${prefix}/stock/slip`,
        method: 'get',
        params: query,
    })
}


/**
 * 资料（修改）
 * @param {*} params
 */
export const stockslipEdit = (params) => {
    return request({
        url: `${prefix}/stock/slip`,
        method: 'put',
        data: params,
    })
}


/**
 * 快递公司（查询列表）
 */
export const expresslist = (query) => {
    return request({
        url: `${prefix}/express/list`,
        method: 'get',
        params: query,
    })
}


/**
 * 发货单（创建）
 * @param {*} params
 */
export const createshipslip = (params) => {
    return request({
        url: `${prefix}/ship/slip`,
        method: 'post',
        data: params,
    })
}

/**
 * 发货单（查询列表） 
 * @param {*} query
 */
export const shipliplist = (query) => {
    return request({
        url: `${prefix}/ship/slip/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 发货单（查询详情）
 * @param {*} query
 */
export const shipslipOne = (query) => {
    return request({
        url: `${prefix}/ship/slip`,
        method: 'get',
        params: query,
    })
}


/**
 * 发货 
 * @param {*} params
 */
export const shipshipping = (params) => {
    return request({
        url: `${prefix}/ship/shipping`,
        method: 'post',
        data: params,
    })
}

/**
 * 快递（查询信息）
 * @param {*} query
 */
export const shipexpressOne = (query) => {
    return request({
        url: `${prefix}/ship/express`,
        method: 'get',
        params: query,
    })
}


/**
 * 发货单（批量删除）
 * @param {*} params
 */
export const shipsliplistDelete = (params) => {
    return request({
        url: `${prefix}/ship/slip/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 发货单（更新）
 * @param {*} params
 */
export const shipslipEdit = (params) => {
    return request({
        url: `${prefix}/ship/slip`,
        method: 'put',
        data: params,
    })
}


/**
 *  ocr识别 
 * @param {*} params
 */
export const expressocr = (params) => {
    return request({
        url: `${prefix}/express/ocr`,
        method: 'post',
        data: params,
    })
}


/**
 * 发货单（导出）
 * @param {*} query
 */
export const shipliplistExport = (query) => {
    return request({
        url: `${prefix}/ship/slip/export`,
        method: 'get',
        params: query,
    })
}

/**
 *  发货单（拆分更新）
 * @param {*} params
 */
export const shipslipsplitUpdate = (params) => {
    return request({
        url: `${prefix}/ship/slip/splitUpdate`,
        method: 'post',
        data: params,
    })
}


/**
 *学生（(批量修改) 
 * @param {*} params
 */
export const editstudentBasiclist = (params) => {
    return request({
        url: `${prefix}/studentBasic/list`,
        method: 'put',
        data: params,
    })
}

/**
 * 教研服务（查询列表）
 * @param {*} query
 */
export const teacherServicelist = (query) => {
    return request({
        url: `${prefix}/student/teacherService/list`,
        method: 'get',
        params: query,
    })
}

/**
 *教研服务（批量更新）
 * @param {*} params
 */
export const editteacherServicelist = (params) => {
    return request({
        url: `${prefix}/student/teacherService/list`,
        method: 'put',
        data: params,
    })
}

/**
 * 教研服务（批量删除）
 * @param {*} params
 */
export const teacherServicelistDelete = (params) => {
    return request({
        url: `${prefix}/student/teacherService/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 教研服务记录（查询列表）
 * @param {*} query
 */
export const teacherServiceloglist = (query) => {
    return request({
        url: `${prefix}/teacherService/log/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 教研服务记录（创建）
 * @param {*} params
 */
export const createteacherServicelog = (params) => {
    return request({
        url: `${prefix}/teacherService/log`,
        method: 'post',
        data: params,
    })
}

/**
 * 教研服务记录（查询详情）
 * @param {*} query
 */
export const teacherServicelogOne = (query) => {
    return request({
        url: `${prefix}/teacherService/log`,
        method: 'get',
        params: query,
    })
}

/**
 * 教研服务记录（修改）
 * @param {*} params
 */
export const editteacherServicelog = (params) => {
    return request({
        url: `${prefix}/teacherService/log`,
        method: 'put',
        data: params,
    })
}

/**
 * 教研服务记录（爱特人已读修改）
 * @param {*} params
 */
export const teacherServicelogaitRead = (params) => {
    return request({
        url: `${prefix}/teacherService/log/aitRead`,
        method: 'put',
        data: params,
    })
}


/**
 * 教研服务记录（删除）
 * @param {*} params
 */
export const teacherServicelogDelete = (params) => {
    return request({
        url: `${prefix}/teacherService/log`,
        method: 'delete',
        data: params,
    })
}


/**
 * 教研服务任务（查询列表）
 * @param {*} query
 */
export const teacherServicetasklist = (query) => {
    return request({
        url: `${prefix}/teacherService/task/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 教研服务任务（创建）
 * @param {*} params
 */
export const createteacherServicetask = (params) => {
    return request({
        url: `${prefix}/teacherService/task`,
        method: 'post',
        data: params,
    })
}


/**
 * 教研服务任务（修改）
 * @param {*} params
 */
export const editteacherServicetask = (params) => {
    return request({
        url: `${prefix}/teacherService/task`,
        method: 'put',
        data: params,
    })
}


/**
 * 教研服务任务（删除）
 * @param {*} params
 */
export const teacherServicetaskDelete = (params) => {
    return request({
        url: `${prefix}/teacherService/task`,
        method: 'delete',
        data: params,
    })
}

/**
 * 教研服务任务（查询详情）
 * @param {*} query
 */
export const teacherServicetaskOne = (query) => {
    return request({
        url: `${prefix}/teacherService/task`,
        method: 'get',
        params: query,
    })
}


/**
 * 教研服务任务（查询总消息）
 * @param {*} query
 */
export const teacherServicetaskMsgCount = (query) => {
    return request({
        url: `${prefix}/teacherService/task/totalMsg`,
        method: 'get',
        params: query,
    })
}


/**
 * 模考（创建）
 * @param {*} params
 */
export const mockexamCreate = (params) => {
    return request({
        url: `${prefix}/mock/exam`,
        method: 'post',
        data: params,
    })
}

/**
 * 模考（查询列表）
 * @param {*} query
 */
export const mockexamlist = (query) => {
    return request({
        url: `${prefix}/mock/exam/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 模考（查询详情）
 * @param {*} query
 */
export const mockexamOne = (query) => {
    return request({
        url: `${prefix}/mock/exam`,
        method: 'get',
        params: query,
    })
}

/**
 * 模考（修改）
 * @param {*} params
 */
export const editmockexam = (params) => {
    return request({
        url: `${prefix}/mock/exam`,
        method: 'put',
        data: params,
    })
}

/**
 * 模考（删除）
 * @param {*} params
 */
export const mockexamdelete = (params) => {
    return request({
        url: `${prefix}/mock/exam`,
        method: 'delete',
        data: params,
    })
}


/**
 * 模考试卷（查询列表）
 * @param {*} params
 */
export const mockexampaperlist = (params) => {
    return request({
        url: `${prefix}/mock/exam/paper/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 模考试卷（创建）
 * @param {*} params
 */
export const mockexampaperCreate = (params) => {
    return request({
        url: `${prefix}/mock/exam/paper`,
        method: 'post',
        data: params,
    })
}

/**
 * 模考试卷（查询详情）
 * @param {*} params
 */
export const mockexampaperOne = (params) => {
    return request({
        url: `${prefix}/mock/exam/paper`,
        method: 'get',
        params: params,
    })
}


/**
 * 模考试卷（修改）
 * @param {*} params
 */
export const mockexampaperEdit = (params) => {
    return request({
        url: `${prefix}/mock/exam/paper`,
        method: 'put',
        data: params,
    })
}


/**
 * 模考试卷（删除）
 * @param {*} params
 */
export const mockexampaperDelete = (params) => {
    return request({
        url: `${prefix}/mock/exam/paper`,
        method: 'delete',
        data: params,
    })
}

/**
 * 模考试卷（批量删除）
 * @param {*} params
 */
export const mockexampaperlistDelete = (params) => {
    return request({
        url: `${prefix}/mock/exam/paper/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 模考考试过程（查询列表）
 * @param {*} params
 */
export const mockexamprocesslist = (params) => {
    return request({
        url: `${prefix}/mock/exam/process/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 模考考试过程（创建）
 * @param {*} params
 */
export const mockexamprocessCreate = (params) => {
    return request({
        url: `${prefix}/mock/exam/process`,
        method: 'post',
        data: params,
    })
}


/**
 * 模考考试过程（删除）
 * @param {*} params
 */
export const mockexamprocessDelete = (params) => {
    return request({
        url: `${prefix}/mock/exam/process`,
        method: 'delete',
        data: params,
    })
}

/**
 * 模考考试过程（批量删除）
 * @param {*} params
 */
export const mockexamprocesslistDelete = (params) => {
    return request({
        url: `${prefix}/mock/exam/process/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 模考考试过程（修改）
 * @param {*} params
 */
export const mockexamprocessEdit = (params) => {
    return request({
        url: `${prefix}/mock/exam/process`,
        method: 'put',
        data: params,
    })
}

/**
 * 模考考试过程（查询详情）
 * @param {*} params
 */
export const mockexamprocessOne = (params) => {
    return request({
        url: `${prefix}/mock/exam/process`,
        method: 'get',
        params: params,
    })
}



/**
 * 模考考试过程（批改）
 * @param {*} params
 */
export const mockprocessCorrect = (params) => {
    return request({
        url: `${prefix}/mock/exam/process/correct`,
        method: 'post',
        data: params,
    })
}


/**
 * 机构普通课（创建）
 * @param {*} params
 */
export const normalCourseCreate = (params) => {
    return request({
        url: `${prefix}/company/normalCourse`,
        method: 'post',
        data: params,
    })
}



/**
 * 机构普通课（查询列表）
 * @param {*} params
 */
export const normalCourselist = (params) => {
    return request({
        url: `${prefix}/company/normalCourse/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 机构普通课（查询详情）
 * @param {*} params
 */
export const normalCourseOne = (params) => {
    return request({
        url: `${prefix}/company/normalCourse`,
        method: 'get',
        params: params,
    })
}


/**
 * 机构普通课（修改）
 * @param {*} params
 */
export const normalCourseEdit = (params) => {
    return request({
        url: `${prefix}/company/normalCourse`,
        method: 'put',
        data: params,
    })
}



/**
 * 机构普通课（批量删除）
 * @param {*} params
 */
export const normalCourselisttDelete = (params) => {
    return request({
        url: `${prefix}/company/normalCourse/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 学生导出
 * @param {*} params
 */
export const studentBasicExport = (params) => {
    return request({
        url: `${prefix}/studentBasic/export`,
        method: 'get',
        params: params,
    })
}


